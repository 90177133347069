import React from 'react';
import Layout from '../../components/layout/Layout';
import Head from '../../components/layout/Head';
import ReviewFormStatus from '../../components/organisms/Form/ReviewFormStatus';
import AuthWrapper from '../../components/layout/AuthWrapper';

const ReviewCompletedFormPage = () => {
  return (
    <AuthWrapper userRole="admin">
      <Head title="Review Completed Form" />
      <Layout
        section="review"
        breadcrumbs={[
          { link: '/', label: 'Home' },
          { link: '/review-forms', label: 'Review forms' },
          { label: 'Review form' },
        ]}
      >
        <ReviewFormStatus />
      </Layout>
    </AuthWrapper>
  );
};

export default ReviewCompletedFormPage;
