import { navigate } from 'gatsby';
import React, { useCallback, useEffect, useState } from 'react';
import { IFormInReview } from '../../../context-providers/forms/forms-reducer';
import { fetchAPI } from '../../../utils/fetch-api';
import { getQueryVar } from '../../../utils/query-vars';
import Stack from '../../atoms/stack/Stack';
import {
  Column,
  ColumnR,
  ContentContainer,
  LightContentBox,
  Row,
} from '../../layout/Layout.style';
import configLoader from '../configuration/configuration-loader';
import PDFExport from '../pdf-export/PDFExport';
import { FormTitleHeader, ReviewFormContainer } from './Form.style';
import RenderFormTemplate from './RenderFormTemplate';

const ReviewFormStatus = () => {
  // load form
  const formId = getQueryVar('fid') || '';
  const [formInReview, setFormInReview] = useState<IFormInReview | null>(null);
  const load = useCallback(async () => {
    const res = await fetchAPI<IFormInReview>({
      path: `CompletedFormAssignments/completed/${formId}`,
    });
    if (res.success) {
      setFormInReview(res.body);
    } else {
      navigate('/');
    }
  }, [formId]);

  useEffect(() => {
    load();
  }, [load]);

  if (!formInReview) {
    return null;
  }

  const config = configLoader();
  return (
    <>
      <ReviewFormContainer>
        <ContentContainer small>
          <FormTitleHeader>
            <Row>
              <Column>
                <h1>{formInReview.template.formName}</h1>
              </Column>
              <ColumnR>
                {config.EnablePdfExport && (
                  <PDFExport
                    formTemplate={formInReview.template}
                    learnerName={formInReview.learner.name}
                  />
                )}
              </ColumnR>
            </Row>
          </FormTitleHeader>

          <LightContentBox>
            <form>
              <Stack stackMultiplier={3}>
                <RenderFormTemplate
                  form={formInReview.template}
                  formId={formInReview.id}
                />
              </Stack>
            </form>
          </LightContentBox>
        </ContentContainer>
      </ReviewFormContainer>
    </>
  );
};

export default ReviewFormStatus;
